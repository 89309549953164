* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  // height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  font-family: sans-serif;
  background-color: #181c14;
  color: #ecdfcc;
}
.flex {
  display: flex;
}

.heading {
  background-color: #3c3d37;
  font-family: sans-serif;
  padding: 1rem;
  h1,
  p {
    color: #ecdfcc;
  }
  h1 {
    font-size: 2rem;
    font-weight: 900;
  }
  p {
    font-weight: 300;
  }
}

.heading > h1,
.description {
  margin: 1rem;
  margin-left: 10rem;
}

.converter {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 3rem;
}
.text,
.morse {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  margin: 3rem;
  div {
    margin-bottom: 0.6rem;
    p {
      margin-left: 0.6rem;
    }
  }
  .textInput,
  .morseInput {
    border-radius: 1rem;
    border: 2px solid black;
    padding: 1rem;
    outline: none;
    font-size: 1.2rem;
    background-color: #ecdfcc;
    color: #181c14;
    font-weight: 600;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(7, 1fr);
  text-align: center;
  width: 90%;
  margin-left: 4.5rem;
  margin-bottom: 5rem;
  border-bottom-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.g1 {
  border: 1px solid white;
  grid-column: 1/7;
  background-color: #697565;
  color: #ecdfcc;
  font-weight: 600;
  font-family: sans-serif;
  padding-top: 1.5rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.g {
  color: #697565;
  font-size: 18px;
  border: 1px solid white;
  background-color: #ecdfcc;
  font-family: sans-serif;
  padding-top: 0.5rem;
  justify-content: center;
  flex-direction: column;
  padding-top: 0.8rem;
  font-weight: 700;
}

.g > div {
  height: 50%;
  margin-top: 0.8rem;
  font-size: 18px;
  letter-spacing: 6px;
  background-color: #697565;
  font-family: sans-serif;
  padding-top: 0.5rem;
  padding: 0.5rem;
  color: #181c14;
}

@media (max-width: 640px) {
  body{
    overflow-x: hidden;
  }
  .heading {
    // margin-top: 2rem;
    h1 {
      width: 20rem;
      margin-left: 0rem;
      font-size: 1.3rem;
      font-weight: 900;
    }
    p {
      width: 16rem;
      margin-left: 0rem;
      font-weight: 300;
      font-size: 0.7rem;
    }
  }
  .converter {
    padding: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text,
    .morse {
      width: 65%;
      height: 50%;
    }
  }
  .grid {
    display: none;
  }
  .bgparent{
    padding: 1rem;
  }
  .bg {
    background-image: url("./assets/morsetable.png");
    height: 20rem;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;

  }
}
