* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: #ecdfcc;
  background-color: #181c14;
  width: 100vw;
  font-family: sans-serif;
  overflow-x: hidden;
}

.flex {
  display: flex;
}

.heading {
  background-color: #3c3d37;
  padding: 1rem;
  font-family: sans-serif;
}

.heading h1, .heading p {
  color: #ecdfcc;
}

.heading h1 {
  font-size: 2rem;
  font-weight: 900;
}

.heading p {
  font-weight: 300;
}

.heading > h1, .description {
  margin: 1rem 1rem 1rem 10rem;
}

.converter {
  width: 100%;
  height: 100%;
  padding: 3rem;
  display: flex;
}

.text, .morse {
  flex-direction: column;
  width: 50%;
  margin: 3rem;
  font-weight: 600;
  display: flex;
}

.text div, .morse div {
  margin-bottom: .6rem;
}

.text div p, .morse div p {
  margin-left: .6rem;
}

.text .textInput, .text .morseInput, .morse .textInput, .morse .morseInput {
  color: #181c14;
  background-color: #ecdfcc;
  border: 2px solid #000;
  border-radius: 1rem;
  outline: none;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.grid {
  text-align: center;
  border-bottom-right-radius: 10px;
  grid-template-rows: repeat(7, 1fr);
  grid-template-columns: repeat(6, 1fr);
  width: 90%;
  margin-bottom: 5rem;
  margin-left: 4.5rem;
  display: grid;
}

.g1 {
  color: #ecdfcc;
  background-color: #697565;
  border: 1px solid #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  grid-column: 1 / 7;
  padding-top: 1.5rem;
  font-family: sans-serif;
  font-weight: 600;
}

.g {
  color: #697565;
  background-color: #ecdfcc;
  border: 1px solid #fff;
  flex-direction: column;
  justify-content: center;
  padding-top: .8rem;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.g > div {
  letter-spacing: 6px;
  color: #181c14;
  background-color: #697565;
  height: 50%;
  margin-top: .8rem;
  padding: .5rem;
  font-family: sans-serif;
  font-size: 18px;
}

@media (width <= 640px) {
  body {
    overflow-x: hidden;
  }

  .heading h1 {
    width: 20rem;
    margin-left: 0;
    font-size: 1.3rem;
    font-weight: 900;
  }

  .heading p {
    width: 16rem;
    margin-left: 0;
    font-size: .7rem;
    font-weight: 300;
  }

  .converter {
    flex-direction: column;
    align-items: center;
    padding: 0;
    display: flex;
  }

  .converter .text, .converter .morse {
    width: 65%;
    height: 50%;
  }

  .grid {
    display: none;
  }

  .bgparent {
    padding: 1rem;
  }

  .bg {
    background-image: url("morsetable.e0c233f8.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 20rem;
  }
}
/*# sourceMappingURL=index.304d4d5d.css.map */
